.lf-container {
  width: 100vw;
  overflow: hidden;

  h2,
  h3 {
    color: #333;
    font-family: HKGroteskPro-Medium, serif;
    font-size: 26px;
  }

  .lfc-about {
    background: url('~@/assets/images/about-bg.png') no-repeat;
    background-size: contain;
    background-position: bottom;

    height: 540px;
    margin: 20px 0;
    &>h1 {
      background: linear-gradient(270deg, #4ddaeb, #5eeec3);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-family: HKGroteskPro-Medium, serif;
      font-size: 36px;
      margin: 0 20px;
    }

    &>section {
      color: #333;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;

      margin: 20px;
    }

    &>footer {
      background: linear-gradient(270deg, #4ddaea, #5eeec4);
      border-radius: 34px;
      box-sizing: border-box;
      cursor: pointer;
      height: 50px;
      width: 142px;
      padding: 1px;
      margin: 20px;

      &>div {
        background: #fff;
        border-radius: 34px;
        height: 100%;
        line-height: 45px;
        overflow: hidden;
        text-align: center;
        width: 100%;

        color: #4ddaea;
        font-family: Feather;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    &>ul.lfc-about-icon {
      position: relative;

      &>li:nth-child(1)>img {
        width: 180px;
        position: absolute;
        top: -50px;
        right: -300px;
        transition: right 1s ease-in-out;
      }

      &>li:nth-child(2)>img {
        width: 200px;
        position: absolute;
        top: 10px;
        left: -300px;
        transition: left 1s ease-in-out;
      }

      &>li:nth-child(3)>img {
        width: 200px;
        position: absolute;
        top: 80px;
        right: -300px;
        transition: right 1s ease-in-out;
      }
    }

    &>ul.lfc-about-icon-tip {
      &>li:nth-child(1)>img {
        right: 150px;
        top: -10px;
      }

      &>li:nth-child(2)>img {
        width: 170px;
        left: 1px;
        top: 50px;
      }

      &>li:nth-child(3)>img {
        right: 20px;
      }
    }

  }


  .lfc-appr {

    &>h2 {
      margin: 20px;
      display: flex;
      align-items: center;
      margin-top: 80px;

      &::after {
        content: '';
        display: inline-block;
        margin: 0 10px;
        transform: translateY(2px);
        width: 40px;
        height: 20px;
        background: url('~@/assets/images/hand.png') no-repeat;
        background-size: contain;
      }
    }

    &>section>header {
      color: #333;
      font-family: HKGroteskPro-Regular, serif;
      font-size: 20px;
      font-weight: 600;
    }

    &>section>section {
      color: #333;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;

      margin: 20px;
    }

    &>section.lfc-appr-before {
      padding-left: 5px;
      padding-right: 5px;
      &>header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 50px;

        &::before {
          content: '';
          background: linear-gradient(1turn, #4ddaeb, #5eeec3);
          border-radius: 4px;
          display: inline-block;
          height: 20px;
          margin-left: 20px;
          margin-right: 10px;
          width: 6px;
        }
      }
    }

    &>section.lfc-appr-after {
      padding-right: 20px;
      &>header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 50px;

        &::after {
          content: '';
          background: linear-gradient(1turn, #4ddaeb, #5eeec3);
          border-radius: 4px;
          display: inline-block;
          height: 20px;
          margin-right: 20px;
          margin-left: 10px;
          width: 6px;
        }
      }

      &>section {
        text-align: right;
      }
    }
  }

  .lfc-tech,
  .lfc-solution {
    &>header {
      text-align: center;
      margin: 100px 0 18px;

      &>h2 {
        color: #999;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: 3px;
        margin: 0;
      }

      &>h3 {
        color: #333;
        font-family: HKGroteskPro-Regular, serif;
        font-weight: 600;
        letter-spacing: 7px;
        margin-bottom: 40px;
        margin-top: 10px;
      }
    }

  }

  .lfc-tech-container {
    background: url('~@/assets/images/bg-copy.png') no-repeat;
    background-size: cover;

    .lfc-tech-container-wrapper {
      list-style: none;
      margin: 0;
      padding: 0;

      font-family: HKGroteskPro-Regular, serif;
      font-size: 20px;
      color: #FFF;
      line-height: 20px;

      .lfc-tech-container-content {
        &>header {
          display: flex;
          align-items: center;
          padding: 10px 15px;
          box-sizing: border-box;
          height: 120px;
          background: linear-gradient(0deg, #1a7a8a 50%, rgba(5, 131, 141, .37));
          line-height: 30px;

          &>img {
            margin-right: 20px;
          }
          &>p{
            font-size: 18px;
          }
        }

        &>section {
          height: 0;
          overflow: hidden;
          font-family: HKGroteskPro-Regular, serif;
          font-size: 16px;
          transition: height 1s ease-in-out;
        }

        &>section.selected {
          height: auto;
          line-height: 22px;
          background: url('~@/assets/images/science.png') no-repeat;
          background-size: cover;

          &>div {
            font-family: HKGroteskPro-Regular, serif;
            padding: 10px 20px 20px;
            background: linear-gradient(135deg, #1a7a8a 50%, rgba(5, 131, 141, .37));
            line-height: 35px;
          }
        }
      }
    }

  }

  .lfc-solution {
    .lfc-solution-content {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      height: 80px;
      margin: 40px 0;
      margin-right: 15px;

      .lfc-solution-content-item {
        width: 25%;
        text-align: center;
        font-size: 12px;
        word-wrap: break-word;
        color: #1e889a;;
        // border: 1px solid red;

        &>img {
          height: 30px;
        }
      }
      >img{
        display: flex;
        width: 300px;
        margin-top: 30px;
      }
    }
  }

  .lfc-news {
    display: flex;
    justify-content: space-between;
    margin-top: 300px;

    // width: 100%;
    .lfc-news-item {
      width: 280px;
      height: 420px;
      background-size: 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;

      &>a {
        display: block;
        text-decoration: none;
        height: 100%;
        width: 280px;
        padding: 40px;
        box-sizing: border-box;
        background: rgba($color: #000, $alpha: .45);

        color: hsla(0, 0%, 100%);
        font-family: PingFangSC-Medium, PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 40px;
        position: relative;

        &::after {
          content: 'DETAIL';
          display: block;
          width: 120px;
          height: 40px;
          line-height: 40px;
          background-color: rgba($color: #FFF, $alpha: .9);
          color: #4ddaeb;
          text-align: center;
          border-radius: 20px;
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }

      }

      &.item-0 {
        background-image: url('~@/assets/images/new0.png');
        background-size: 120%;
        background-position-x: -20px;
      }

      &.item-1 {
        background-image: url('~@/assets/images/new1.png');
        background-size: 120%;
        background-position-x: -40px;
        background-position-y: -30px;
        // border: 1px solid red;
      }

      &.item-2 {
        background-image: url('~@/assets/images/new2.png');
        background-size: 110%;
        background-position-x: -20px;
      }
    }
  }

  .lfc-vision {
    background-image: url('~@/assets/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 140px;

    padding: 30px 50px;
    box-sizing: border-box;

    &>img {
      width: 120px;
    }

    &>header {
      color: #fff;
      font-family: HKGroteskPro-Regular, serif;
      font-size: 26px;
      font-weight: 600;
      letter-spacing: 8px;
      margin: 0;
    }

    &>p {
      color: #fff;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
  }

  .lfc-careers-header {
    text-align: center;
    margin: 30px 0 20px;

    &>h2 {
      color: #999;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: 3px;
      margin: 0;
    }

    &>h3 {
      color: #333;
      font-family: HKGroteskPro-Regular, serif;
      font-weight: 600;
      letter-spacing: 7px;
      margin-bottom: 40px;
      margin-top: 10px;
    }
  }

  .lfc-values {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 0 10px;

    .lfc-values-item {
      overflow: hidden;
      width: 45%;
      height: 200px;

      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 9%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      position: relative;

      &::before {
        background: linear-gradient(90deg, #5eeec3, #4ddaeb);
        border-radius: 0 0 10px 10px;
        content: "";
        display: inline-block;
        height: 11px;
        left: 24px;
        position: absolute;
        top: 0;
        width: 51px;
      }

      // border: 1px solid red;

      &>img {
        width: 80%;
      }

      &>p {
        color: #333;
        font-family: HKGroteskPro-Regular, serif;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .lfc-values-item-content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      &>h3 {
        color: #333;
        font-family: HKGroteskPro-Regular, serif;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        margin-top: 10px;
        padding: 10px 0;
        border-bottom: 1px solid #eee;
        width: 100%;
        text-align: center;
      }

      &>p {
        padding: 10px;
        height: 100%;
        color: #333;
        font-family: HKGroteskPro-Regular, serif;
        font-size: 15px;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  .adm-swiper {
    margin-bottom: 100px;
  }

  .lfc-careers {
    height: 480px;
    background: linear-gradient(180deg, #56c1cc, #20799e);

    .lfc-careers-swiper {
      height: 100%;
      background: url('~@/assets/images/fill.png') no-repeat;
      background-size: cover;

      .lfc-careers-swiper-item {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;

        &>section {
          background: hsla(0, 0%, 100%, .13);
          border-radius: 10px;
          box-sizing: border-box;
          margin: 10px 20px;
          padding: 15px 10px;
        }

        &>section>header {
          color: #fff;
          font-family: HKGroteskPro-Regular, serif;
          font-size: 23px;
          line-height: 38px;
          margin: 0 20px 10px;
        }

        &>section>p {
          color: #fff;
          font-family: HKGroteskPro-Regular, serif;
          font-size: 14px;
          line-height: 26px;
          margin: 0 20px;
        }
      }
    }
  }

  .lfc-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0 0;

    &>header {
      color: #52e0df;
      font-family: HKGroteskPro-Regular, serif;
      font-size: 28px;
      font-weight: 600;
      width: 80%;
    }

    &>section {
      width: 80%;

      .lfc-li {
        display: flex;
        flex-direction: row;

        >img {
          height: 16px;
          width: 16px;
          vertical-align: middle;
          margin-right: 16px;
          margin-top: 12px;
        }
      }
    }

    &>section>p {
      color: #333;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }

    &>img {
      width: 80%;
    }
  }
}

.home-about-footer {
  margin-top: 20px;
  text-align: center;
  height: 60px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: #eee;

  font-family: PingFang-SC-Medium, PingFang-SC;
  line-height: 20px;
  .home-about-ban {
    // height: 60px;
    line-height: 30px;
    // transform: translateY(-20px);
    & > a {
      color: #666;
      text-decoration: none;
    }
  }
}