@font-face {
  font-family: "HKGroteskPro-Medium";
  src: url('~@/assets/fonts/HKGroteskPro-Medium.woff2');
}

@font-face {
  font-family: "HKGroteskPro-Regular";
  src: url('~@/assets/fonts/HKGroteskPro-Regular.woff2');
}

@font-face {
  font-family: "Feather";
  src: url('~@/assets/fonts/Feather.ttf');
}

body {
  margin: 0;
  background-color: rgb(240, 240, 240);

  font-family: HKGroteskPro-Regular, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* 隐藏滚动条 */
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}

ul {
  list-style: none;
}