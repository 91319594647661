.lyx-about-wrapper {
  overflow: hidden;

  & > p {
    font-family: HKGroteskPro-Regular,serif;
    font-size:18px;
    font-weight: 400;
    line-height: 24px;
    text-align: justify;
    padding: 10px 15px;
  }

  & > div > img {
    width: 110%;
    transform: translateX(-5%);
  }
}