.lfx-header {
  padding: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #FFF;

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-right: 20px;
    & > li {
      display: block;
      width: 18px;
      margin: 0;
      padding: 0;
      height: 5px;
      border-top: 2px solid #333;
    }
  }
  & > img {
    width: 160px;
  }

  & > nav {
    position: absolute;
    top: 60px;
    right: 5px;
    z-index: 999;
    display: block;
    width: 30%;
    padding: 0 20px;
    overflow: hidden;

    color: #333;
    font-family: HKGroteskPro-Medium, serif;
    font-size: 20px;

    transition: height 1s ease-in-out;

    & > div.lfx-header-nav {
      margin: 20px 0;

      & > .lfx-header-nav-child {
        white-space: nowrap;
        color: #999;
        &.selected {
          color: #4ddaeb;
        }
      }
    }

    .selected {
      color: #4ddaeb;
    }

    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 9%);
    background-color: #FFF;
  }
}